import { useDispatch, useSelector } from "react-redux";
import Table from "../ui/table/TableController";
import {
  companiesSelector,
  updateTax,
  updateTaxState,
} from "../../redux/slices/companiesSlice";
import { uiSelector } from "../../redux/slices/uiSlice";
import Loading from "./tableState/Loading";
import NoSearch from "./tableState/NoSearch";
import NoItems from "./tableState/NoItems";

const Tax = () => {
  const { taxes, tableLoading } = useSelector(companiesSelector);
  const dispatch = useDispatch();
  const { searchQuery } = useSelector(uiSelector);
  
  const filteredTaxes = taxes.filter(
    (i) =>
      String(i.tax_id).includes(searchQuery) ||
      i.tax_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      String(i.pms_id).includes(searchQuery)
  );

  const headerData = [
    { id: 132, label: "Company", width: "16%", sortable: false },
    { id: 232, label: "Id", width: "16%", sortable: true },
    { id: 342, label: "Tax Name", width: "16%", sortable: true },
    { id: 332, label: "PMS ID", width: "16%", sortable: true },
    { id: 532, label: "Action", width: "16%", sortable: false },
  ];

  const rowKeys = [
    "company_id",
    "tax_id",
    "tax_name",
    "pms_id"
  ];

  const editable = ["pms_id"];

  const sortByData = [
    {},
    {
      label: "tax_id",
      type: "string",
    },
    {
      label: "tax_name",
      type: "string",
    },
    {
      label: "pms_id",
      type: "number",
    }
  ];

  const updateAction = (x) => {
    dispatch(updateTaxState(x));
    return updateTax(x);
  };

  if (tableLoading) return <Loading />;

  if (filteredTaxes.length === 0 && searchQuery) return <NoSearch />;
  if (filteredTaxes.length === 0) return <NoItems />;

  return (
    <Table
      sortByData={sortByData}
      headerData={headerData}
      rowKeys={rowKeys}
      data={filteredTaxes}
      editable={editable}
      row_key="tax_id"
      updateAction={updateAction}
    />
  );
};

export default Tax;
