import styled from "@emotion/styled";

import { useSelector } from "react-redux";
import {
  companiesSelector,
  currentSelector,
} from "../redux/slices/companiesSlice";
import { uiSelector } from "../redux/slices/uiSlice";

import LocationBarButton from "./ui/LocationBarButton";
import SearchBar from "./ui/SearchBar";

const Wrapper = styled.div``;

const LocationWrapper = styled.div`
  display: flex;
  padding: 16px;
  flex-wrap: wrap;
  max-height: 168px;
  overflow-y: auto;
`;

const LocationBar = () => {
  const { companiesList } = useSelector(companiesSelector);
  const { currentId } = useSelector(currentSelector);
  const { locationQuery } = useSelector(uiSelector);

  const filteredLocations = companiesList.filter((i) =>
    i.company_name.toLowerCase().includes(locationQuery.toLowerCase())
  );

  return (
    <Wrapper>
      <SearchBar />
      <LocationWrapper>
        {filteredLocations.map((i) => (
          <LocationBarButton
            current={currentId === i.company_id}
            key={i.company_id}
            item={i}
          />
        ))}
      </LocationWrapper>
    </Wrapper>
  );
};

export default LocationBar;
