import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import { ReactComponent as Location } from "../../assets/location.svg";
import {
  setCurrentId,
  getFloors,
  getItemizers,
  getCategory,
  getPayments,
  getCategories,
  getCompanies,
} from "../../redux/slices/companiesSlice";
import { useLocation } from "react-router-dom";

const LocationStyle = styled.div`
  background: ${({ bg }) => bg};
  color: white;
  padding: 16px;
  height: 120px;
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 12px;
  border-radius: 6px;
  margin-right: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  text-align: center;
`;

const LocationBarButton = ({ item, current }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const bg = current ? "#5187e0" : "#232a3b";

  const fnDict = {
    "/": getCompanies,
    "/details": getFloors,
    "/details/itemizers": getItemizers,
    "/details/category": getCategories,
    "/details/payments": getPayments,
  };

  return (
    <LocationStyle
      bg={bg}
      onClick={() => {
        dispatch(setCurrentId(item.company_id));
        if (pathname in fnDict) {
          dispatch(fnDict[pathname]());
        }
      }}
    >
      <Location fill="white" />
      <p>{item.company_name}</p>
    </LocationStyle>
  );
};

export default LocationBarButton;
