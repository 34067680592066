import { Sortable, Th } from "@lightspeed/cirrus-table";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { uiSelector } from "../../../redux/slices/uiSlice";

const StickyTh = styled(Th)`
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 100;
  background: white;
`;

const TableHeaderItem = ({
  item,
  index,
  sortState,
  changeSortState,
  scrollPos,
}) => {
  const { sortable, id, width, label } = item;
  const sortValues = ["unsorted", "desc", "asc"];
  const { theme } = useSelector(uiSelector);

  if (sortable && !scrollPos)
    return (
      <Sortable.Th
        className={theme === "flame" ? "" : "dark-theme"}
        key={id}
        width={width}
        direction={sortValues[sortState[index]]}
        onClick={() => {
          changeSortState(index);
        }}
      >
        {label}
      </Sortable.Th>
    );

  return (
    <StickyTh
      className={theme === "flame" ? "" : "dark-theme"}
      key={id}
      width={width}
    >
      {label}
    </StickyTh>
  );
};

export default TableHeaderItem;
