import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { api } from "../../api/api";

export const getLogin = createAsyncThunk("auth/getLogin", async (loginData) => {
  const { data } = await api.login(loginData);
  return data[0];
});

const initialState = {
  token: "",
  error: "",
  loading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLogin.pending, (state) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(getLogin.fulfilled, (state, action) => {
        state.loading = false;
        if ("token_id" in action.payload) {
          state.error = "";
          state.token = action.payload.token_id;
        } else {
          state.error = "Incorrect username or password";
        }
      })
      .addCase(getLogin.rejected, (state) => {
        state.loading = false;
        state.error = "Incorrect username or password";
      });
  },
});

export const authSelector = (state) => state.auth;

export default authSlice.reducer;
