import { useDispatch, useSelector } from "react-redux";
import Table from "../ui/table/TableController";
import {
  companiesSelector,
  updatePayment,
  updatePaymentState,
} from "../../redux/slices/companiesSlice";
import { uiSelector } from "../../redux/slices/uiSlice";
import Loading from "./tableState/Loading";
import NoSearch from "./tableState/NoSearch";
import NoItems from "./tableState/NoItems";

const Payments = () => {
  const {
    payments,
    tableLoading,
    cardTypesLoading,
    houseAccountsLoading,
    cardTypes,
    houseAccounts
  } = useSelector(companiesSelector);

  const dispatch = useDispatch();
  const { searchQuery } = useSelector(uiSelector);

  const filteredPayments = payments.filter(
    (i) =>
      String(i.payment_id).includes(searchQuery) ||
      i.payment_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      //i.pms_account_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
      String(i.pms_id).includes(searchQuery)
  );

  const headerData = [
    { id: 132, label: "Company", width: "16%", sortable: false },
    { id: 232, label: "Id", width: "16%", sortable: true },
    { id: 342, label: "Name", width: "16%", sortable: true },
    { id: 332, label: "PMS ID", width: "16%", sortable: true },
    { id: 362, label: "PMS Account ID", width: "16%", sortable: true },
    { id: 532, label: "Action", width: "16%", sortable: false },
  ];

  const rowKeys = [
    "company_id",
    "payment_id",
    "payment_name",
    "pms_id",
    "pms_account_id",
  ];

  const editable = ["pms_id", "pms_account_id"];

  const editableData = {
    pms_id: {
      type: 'listBox',
      inputType: 'number',
      items: houseAccounts.map((houseAccount, i) => {
        const idValue = Number(houseAccount.house_name.split('|')[0])

        return {
          label: houseAccount.house_name,
          value: !Number.isNaN(idValue) ? idValue : houseAccount.house_name
        }
      })
    },
    pms_account_id: {
      type: 'listBox',
      inputType: 'text',
      items: cardTypes.map((cardType, i) => ({
        label: cardType.cardcode,
        value: cardType.cardcode
      }))
    },
  }

  const sortByData = [
    {},
    {
      label: "payment_id",
      type: "number",
    },
    {
      label: "payment_name",
      type: "string",
    },
    {
      label: "pms_id",
      type: "number",
    },
    {
      label: "pms_account_id",
      type: "number",
    },
  ];

  const updateAction = (x) => {
    dispatch(updatePaymentState(x));
    return updatePayment(x);
  };

  if (tableLoading || cardTypesLoading || houseAccountsLoading) return <Loading />;

  if (filteredPayments.length === 0 && searchQuery) return <NoSearch />;

  if (filteredPayments.length === 0) return <NoItems />;

  return (
    <Table
      sortByData={sortByData}
      headerData={headerData}
      rowKeys={rowKeys}
      data={filteredPayments}
      editable={editable}
      editableData={editableData}
      row_key="payment_id"
      updateAction={updateAction}
    />
  );
};

export default Payments;
