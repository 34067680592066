import { useSelector, useDispatch } from "react-redux";
import Table from "../ui/table/TableController";
import {
  companiesSelector,
  updateFloorState,
} from "../../redux/slices/companiesSlice";
import { updateFloor } from "../../redux/slices/companiesSlice";
import { uiSelector } from "../../redux/slices/uiSlice";
import Loading from "./tableState/Loading";
import NoSearch from "./tableState/NoSearch";
import NoItems from "./tableState/NoItems";

const ReveneuCentar = () => {
  const { floors, tableLoading } = useSelector(companiesSelector);
  const dispatch = useDispatch();
  const { searchQuery } = useSelector(uiSelector);

  const filteredFloors = floors.filter(
    (i) =>
      String(i.floor_id).includes(searchQuery) ||
      i.floor_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      String(i.pms_id).includes(searchQuery)
  );

  const headerData = [
    { id: 1, label: "Company", width: "20%", sortable: false },
    { id: 2, label: "Id", width: "20%", sortable: true },
    { id: 3, label: "Name", width: "20%", sortable: true },
    { id: 4, label: "PMS ID", width: "20%", sortable: true },
    { id: 5, label: "Action", width: "20%", sortable: false },
  ];

  const rowKeys = ["company_id", "floor_id", "floor_name", "pms_id"];

  const editable = ["pms_id"];

  const sortByData = [
    {},
    {
      label: "floor_id",
      type: "number",
    },
    {
      label: "floor_name",
      type: "string",
    },
    {
      label: "pms_id",
      type: "number",
    },
    {},
  ];

  const updateAction = (x) => {
    dispatch(updateFloorState(x));
    return updateFloor(x);
  };

  if (tableLoading) return <Loading />;

  if (filteredFloors.length === 0 && searchQuery) return <NoSearch />;

  if (filteredFloors.length === 0) return <NoItems />;

  return (
    <Table
      sortByData={sortByData}
      headerData={headerData}
      rowKeys={rowKeys}
      data={filteredFloors}
      editable={editable}
      row_key="floor_id"
      updateAction={updateAction}
    />
  );
};

export default ReveneuCentar;
