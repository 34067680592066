import Input from "./ui/Input";
import styled from "@emotion/styled";
import { Card } from "@lightspeed/flame/Card";
import CategoryBarItem from "./ui/CategoryBarItem";
import { useDispatch, useSelector } from "react-redux";
import {
  getFloors,
  getItemizers,
  getCategories,
  getPayments,
  getTaxes,
  getCardTypes,
  getHouseAccounts,
} from "../redux/slices/companiesSlice";
import { setSearchQuery, uiSelector } from "../redux/slices/uiSlice";

const Wrapper = styled.div`
  margin-bottom: 16px;
`;

const InputWrapper = styled.div`
  padding: 6px;
  max-width: 380px;
`;

const Flexed = styled.div`
  display: flex;
`;

const CategoryList = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  padding: 0 32px;
`;

const CategoryBar = () => {
  const dispatch = useDispatch();
  const { searchQuery } = useSelector(uiSelector);

  const tableSelect = [
    { id: 12345, url: "/details", label: "Revenue center" },
    { id: 12346, url: "/details/itemizers", label: "Itemizers" },
    { id: 12347, url: "/details/category", label: "Category" },
    { id: 12348, url: "/details/payments", label: "Payments" },
    { id: 12349, url: "/details/tax", label: "Tax" },
    { id: 12350, url: "/details/other", label: "Other" },
    { id: 12351, url: "/details/reports", label: "Reports" },
    { id: 12352, url: "/details/log", label: "Log" },
  ];

  const getTheFloors = () => dispatch(getFloors());
  const getTheItemizers = () => dispatch(getItemizers());
  const getTheCategories = () => {
    dispatch(getCategories());
    dispatch(getItemizers());
  };
  const getThePayments = () => {
    dispatch(getCardTypes());
    dispatch(getHouseAccounts());
    dispatch(getPayments());
  }
  const getTheTaxes = () => dispatch(getTaxes());

  return (
    <Wrapper>
      <Card>
        <Flexed>
          <CategoryList>
            <CategoryBarItem {...tableSelect[0]} onClick={getTheFloors} />
            <CategoryBarItem {...tableSelect[1]} onClick={getTheItemizers} />
            <CategoryBarItem {...tableSelect[2]} onClick={getTheCategories} />
            <CategoryBarItem {...tableSelect[3]} onClick={getThePayments} />
            <CategoryBarItem {...tableSelect[4]} onClick={getTheTaxes} />
            <CategoryBarItem {...tableSelect[5]} onClick={() => {}} />
            <CategoryBarItem {...tableSelect[6]} onClick={() => {}} />
            <CategoryBarItem {...tableSelect[7]} onClick={() => {}} />
          </CategoryList>
          <InputWrapper>
            <Input
              value={searchQuery}
              valueSet={(e) => dispatch(setSearchQuery(e))}
              placeholder="Search data..."
            />
          </InputWrapper>
        </Flexed>
      </Card>
    </Wrapper>
  );
};

export default CategoryBar;
