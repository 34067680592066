import styled from "@emotion/styled";
import { Card } from "@lightspeed/flame/Card";
import { Button } from "@lightspeed/flame/Button";
import Input from "../ui/Input";
import { useState } from "react";

import spinner from "../../assets/spinner.gif";
import { useDispatch, useSelector } from "react-redux";

import {
  setAlert,
  setLocationQuery,
  uiSelector,
} from "../../redux/slices/uiSlice";
import { syncCompany } from "../../redux/slices/companiesSlice";

const SearchStyle = styled.div``;
const InputWrapper = styled.div`
  width: 300px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Img = styled.img`
  height: 24px;
  width: auto;
`;

const SearchBar = () => {
  const [value, valueSet] = useState("");
  const [loading, loadingSet] = useState(false);
  const dispatch = useDispatch();
  const { locationQuery } = useSelector(uiSelector);

  const handleClick = () => {
    loadingSet(true);

    dispatch(syncCompany()).then((res) => handleRes(res));

    const handleRes = (res) => {
      const result = res.payload[0];
      if (result.errorCode === "1000") {
        loadingSet(false);
        dispatch(
          setAlert({
            type: "danger",
            title: "Sync Failed",
            text: "Sync was not 100% successful",
          })
        );
      }
      if (result.sucess === "1") {
        loadingSet(false);
        dispatch(
          setAlert({
            type: "success",
            title: "Sync Successful",
            text: "Sync was done  successfuly!",
          })
        );
      }
    };
  };

  return (
    <SearchStyle>
      <Card>
        <InputWrapper>
          <Input
            value={locationQuery}
            valueSet={(e) => dispatch(setLocationQuery(e))}
            placeholder="Search locations..."
          />
          <Button
            onClick={handleClick}
            variant="secondary"
            fill={loading ? "" : "true"}
          >
            {loading ? <Img src={spinner} alt="loader" /> : "Sync Company"}
          </Button>
        </InputWrapper>
      </Card>
    </SearchStyle>
  );
};

export default SearchBar;
