import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { uiSelector } from "../redux/slices/uiSlice";
import logo from "../assets/login-logo.png";

const WrapperStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const Frame = styled.div`
  padding: 32px 0;
  border: solid 1px rgba(171, 179, 179, 0.7);
  background: rgb(238, 238, 238);
  box-shadow: 0 0.125rem 0.25rem rgba(12, 13, 13, 0.15);
`;

const Inner = styled.div`
  padding: 32px;
  padding-bottom: 8px;
  background: #fff;
  border: solid 1px rgba(171, 179, 179, 0.7);
  border-left: none;
  border-right: none;

  & > img {
    width: 340px;
    height: auto;
    display: block;
  }
`;

export const Notification = styled.p`
  font-size: 0.8rem;
  font-weight: 600;
  opacity: 0.9;
  margin-top: 8px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 32px;
`;

const Wrapper = ({ children }) => {
  const { theme } = useSelector(uiSelector);

  return (
    <WrapperStyle>
      <Frame className={theme === "flame" ? "" : "dark-login-frame"}>
        <Inner className={theme === "flame" ? "" : "dark-login-inner"}>
          <img src={logo} alt="datastems" />
          {children}
        </Inner>
      </Frame>
    </WrapperStyle>
  );
};

export default Wrapper;
