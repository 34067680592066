import { useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import useId from "../../../hooks/useId";
import ListboxRadio from "./ListboxRadio";
import { Input } from "@lightspeed/flame/Input";

const Listbox = ({ name, items, inputType = "text", onInputKeyDown }) => {
  const { control, setValue } = useFormContext();
  const listboxId = useId("listbox");
  const curValue = useWatch({ control, name });
  const inputTypeRef = useRef(
    items.some((item) => item.value === curValue) ? "radio" : "input"
  );

  return (
    <div>
      <div style={{ marginBottom: 12 }}>
        <Input
          type={inputType}
          value={inputTypeRef.current === "input" ? curValue ?? "" : ""}
          onChange={({ target: { value } }) => {
            inputTypeRef.current = "input";

            const isNum =
              inputType === "number" &&
              value !== "" &&
              !Number.isNaN(Number(value));

            setValue(name, isNum ? Number(value) : value);
          }}
          onKeyDown={onInputKeyDown}
        />
      </div>

      {items.map((item, i) => (
        <ListboxRadio
          key={i}
          name={listboxId}
          label={item.label}
          value={item.value}
          checked={inputTypeRef.current === "radio" && item.value === curValue}
          onChange={() => {
            inputTypeRef.current = "radio";
            setValue(name, item.value);
          }}
        />
      ))}
    </div>
  );
};

export default Listbox;
