import styled from "@emotion/styled";
import { Card } from "@lightspeed/flame/Card";

const Wrapper = styled(Card)`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 2rem;
    font-weight: 700;
    opacity: 0.5;
  }
`;

const NoSearch = () => {
  return (
    <Wrapper>
      <p>No items match your search</p>
    </Wrapper>
  );
};

export default NoSearch;
