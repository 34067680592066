import { useState } from "react";
import styled from "@emotion/styled";

import SidebarLogout from "./sidebar/SidebarLogout";
import SidebarDarkmode from "./sidebar/SidebarDarkmode";
import SidebarList from "./sidebar/SidebarList";
import ToggleButton from "./sidebar/ToggleButton";

import logo from "../assets/sidebar-logo.png";

const SidebarInner = styled.div`
  height: 100vh;
  background: #232a3b;
  min-width: 280px;
  display: flex;
  flex-direction: column;
`;

const LogoWrapper = styled.div`
  display: flex;
  padding: 32px 0;
  justify-content: center;

  & > img {
    height: auto;
    width: 220px;
  }
`;

const Sidebar = () => {
  const [show, showSet] = useState(true);

  return (
    <>
      {show && (
        <SidebarInner>
          <LogoWrapper>
            <img src={logo} alt="datastems" />
          </LogoWrapper>
          <SidebarLogout />
          <SidebarList />
          <SidebarDarkmode />
        </SidebarInner>
      )}
      <ToggleButton onClick={() => showSet((old) => !old)} />
    </>
  );
};

export default Sidebar;
