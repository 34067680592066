import styled from "@emotion/styled";

import { useLocation, Link } from "react-router-dom";

const Wrapper = styled(Link)`
  display: flex;
  align-items: center;
  color: white;
  padding: 12px;
  cursor: pointer;
  background: ${({ bg }) => bg};

  .icon {
    height: 28px;
    width: auto;
    margin-right: 8px;
  }
`;

const SidebarItem = ({ item: { url, svg, label } }) => {
  const { pathname } = useLocation();
  let currentItemBg = "#232a3b";
  if (url === "/") {
    currentItemBg = pathname === url ? "#5187e0" : "#232a3b";
  } else {
    currentItemBg = pathname.includes(url) ? "#5187e0" : "#232a3b";
  }

  return (
    <Wrapper bg={currentItemBg} to={url}>
      <img className="icon" src={svg} alt="icon" />
      <p>{label}</p>
    </Wrapper>
  );
};

export default SidebarItem;
