import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { currentSelector } from "../redux/slices/companiesSlice";

const HomeStyle = styled.div`
  padding: 16px;
`;

const Home = () => {
  const { currentCompany } = useSelector(currentSelector);
  var { line_1, line_2, line_3, line_4, line_5, line_6 } = currentCompany;
  
  // var text = "175 - Tees\n187 - ACC CATALOG COURSE SPRING 2016\n207 - lds";
  var text = line_4;


  //console.log(line_4)
  //console.log(text)

  return (
    <HomeStyle>
      <p>{line_1}</p>
      <br />
      <p>{line_2}</p>
      <p>{line_3}</p>
      <br />
   
      <div style={{ whiteSpace: "pre" }}>{text}</div>
    
      <p>{line_5}</p>
      <p>{line_6}</p>
    </HomeStyle>
  );
};

export default Home;
