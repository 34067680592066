import { Thead, Tr, Td } from "@lightspeed/cirrus-table";
import { useSelector } from "react-redux";
import { uiSelector } from "../../../redux/slices/uiSlice";
import TableHeaderItem from "./TableHeaderItem";

const TableHeader = ({ data, sortState, changeSortState, scrollPos }) => {
  const { theme } = useSelector(uiSelector);

  return (
    <Thead>
      <Tr className={`${theme === "flame" ? "" : "dark-table"}`}>
        {data.map((item, index) => (
          <TableHeaderItem
            sortState={sortState}
            changeSortState={changeSortState}
            index={index}
            item={item}
            scrollPos={scrollPos}
            key={item.id}
          />
        ))}
      </Tr>
    </Thead>
  );
};

export default TableHeader;
