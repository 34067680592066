import styled from "@emotion/styled";
import SidebarItem from "./SidebarItem";

import dash from "../../assets/chart.svg";
import details from "../../assets/clipboard.svg";

const Wrapper = styled.div`
  flex-grow: 1;

  margin-top: 48px;
`;

const sideData = [
  {
    id: 234,
    label: "Dashboard",
    url: "/",
    svg: dash,
  },
  {
    id: 734,
    label: "Details",
    url: "/details",
    svg: details,
  },
];

const SidebarList = () => {
  return (
    <Wrapper>
      {sideData.map((i) => (
        <SidebarItem key={i.id} item={i} />
      ))}
    </Wrapper>
  );
};

export default SidebarList;
