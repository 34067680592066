import { Button } from "@lightspeed/flame/Button";

const ButtonComponent = ({ children, tabIndex, register, type = "submit" }) => {
  return (
    <Button
      variant="primary"
      fill="true"
      block="false"
      tabIndex={tabIndex}
      type={type}
    >
      {children}
    </Button>
  );
};

export default ButtonComponent;
