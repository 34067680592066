import { Dialog } from "@lightspeed/flame/Dialog";

const Remove = ({ isOpen, hide }) => {
  return (
    <Dialog
      title="Remove Item"
      message="Are you sure you want to remove this item?"
      cancelText="Cancel"
      confirmText="Yes"
      type="danger"
      isOpen={isOpen}
      onCancel={hide}
    />
  );
};

export default Remove;
