import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import uiSlice from "./slices/uiSlice";
import companiesSlice from "./slices/companiesSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    ui: uiSlice,
    companies: companiesSlice,
  },
});
