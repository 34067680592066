import styled from "@emotion/styled";
import useId from "../../../hooks/useId";

const Box = styled.div`
  display: flex;
`;

const Input = styled.input`
  flex-shrink: 0;
  margin-right: 8px;
`;

const Label = styled.label`
  flex-grow: 1;
  cursor: pointer;
`;

const ListboxRadio = ({ name, value, checked, label, onChange }) => {
  const radioId = useId("listbox_radio");

  return (
    <Box>
      <Input
        type="radio"
        id={radioId}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />

      <Label htmlFor={radioId}>{label}</Label>
    </Box>
  );
};

export default ListboxRadio;
