import { useSelector } from "react-redux";
import Table from "../ui/reports/Table";
import { companiesSelector, getLogs } from "../../redux/slices/companiesSlice";
import styled from "@emotion/styled";
import DatePicker from "../ui/reports/DatePicker";

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

const Log = () => {
  const { logs } = useSelector(companiesSelector);

  const rows = ["time", "receipt_id", "posting_status", "error_description"];

  return (
    <Wrapper>
      <Table data={logs} rows={rows} />
      <DatePicker updateAction={getLogs} />
    </Wrapper>
  );
};

export default Log;
