import styled from "@emotion/styled";
import { useState } from "react";

import { Card } from "@lightspeed/flame/Card";
import { Table } from "@lightspeed/cirrus-table";

import Row from "./Row";
import TableHeader from "./TableHeader";

import { useSortState } from "../../../hooks/useSortState";
import { debounce } from "debounce";
import { useSelector } from "react-redux";
import { uiSelector } from "../../../redux/slices/uiSlice";

const TableWrapper = styled(Card)`
  flex: 1;
  position: relative;
  overflow: auto;
`;
const TableInner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const TableController = ({
  headerData,
  rowKeys,
  data,
  sortByData,
  editable,
  editableData,
  row_key,
  updateAction,
}) => {
  // const { searchQuery, theme } = useStoreState((props) => props.ui);change
  const [searchQuery] = [""];
  const [scrollPos, scrollPosSet] = useState(false);
  const { theme } = useSelector(uiSelector);

  const { sortedData, sortState, changeSortState } = useSortState({
    data,
    sortByData,
  });

  const debouncedLog = debounce((y) => scrollPosSet(y !== 0), 50);

  const handleScroll = (e) => {
    debouncedLog(e.target.scrollTop);
  };

  if (sortedData.length === 0 && searchQuery) {
    return (
      <div className="loader-no-item">
        <h3>No items match your search</h3>
      </div>
    );
  }

  return (
    <TableWrapper top="true" onScroll={handleScroll}>
      <TableInner>
        <Table className={theme === "flame" ? "" : "dark-table"}>
          <TableHeader
            data={headerData}
            sortState={sortState}
            changeSortState={changeSortState}
            scrollPos={scrollPos}
          />
          <tbody>
            {sortedData.map((item) => (
              <Row
                rowKeys={rowKeys}
                updateAction={updateAction}
                editable={editable}
                editableData={editableData}
                key={item[row_key]}
                item={item}
              />
            ))}
          </tbody>
        </Table>
      </TableInner>
    </TableWrapper>
  );
};

export default TableController;
