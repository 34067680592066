import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FlameTheme, FlameGlobalStyles } from "@lightspeed/flame/Core";
import { useEffect } from "react";

// Pages
import Recover from "./pages/recover";
import Login from "./pages/login";
import Layout from "./pages/layout";

import Popup from "./popup/Popup";
import Notification from "./popup/Notification";

import { authSelector } from "./redux/slices/authSlice";
import { uiSelector, setTheme } from "./redux/slices/uiSlice";

const App = () => {
  const { token } = useSelector(authSelector);
  const { alert, theme } = useSelector(uiSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    const storage = JSON.parse(localStorage.getItem("theme"));
    if (storage) {
      dispatch(setTheme(storage));
    }
  }, [dispatch]);

  let componentsList = token ? (
    <Switch>
      <Route path="/" component={Layout} />
    </Switch>
  ) : (
    <Switch>
      <Route path="/recover" component={Recover} />
      <Route exact path="/" component={Login} />
      <Redirect to="/" />
    </Switch>
  );

  return (
    <FlameTheme themeName={theme}>
      <FlameGlobalStyles themeName={theme} />
      {alert.type !== "" && <Notification />}
      <Popup />
      <BrowserRouter>{componentsList}</BrowserRouter>
    </FlameTheme>
  );
};

export default App;
