import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import { toggleTheme } from "../../redux/slices/uiSlice";

const Wrapper = styled.div`
  padding: 24px;
  margin: 8px 24px;
  text-align: center;
  color: #bbb;
  font-size: 0.9rem;
  font-weight: 600;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.1s;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }

  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const SidebarDarkmode = () => {
  const dispatch = useDispatch();
  return <Wrapper onClick={() => dispatch(toggleTheme())}>Dark Mode</Wrapper>;
};

export default SidebarDarkmode;
