import Remove from "./Remove";
import { useSelector, useDispatch } from "react-redux";
import { setPopup, uiSelector } from "../redux/slices/uiSlice";

const Popup = () => {
  const dispatch = useDispatch();
  const { popup } = useSelector(uiSelector);

  const hide = () => dispatch(setPopup({ id: "", type: "" }));

  return (
    <>
      <Remove isOpen={popup.type === "remoteItem"} hide={hide} />
    </>
  );
};

export default Popup;
