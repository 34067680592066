import spinner from "../../../assets/spinner.gif";
import { Card } from "@lightspeed/flame/Card";
import styled from "@emotion/styled";

const Wrapper = styled(Card)`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loading = () => {
  return (
    <Wrapper>
      <img src={spinner} alt="spinner" />
    </Wrapper>
  );
};

export default Loading;
