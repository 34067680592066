import styled from "@emotion/styled";

import user from "../../assets/user.svg";
import { Button } from "@lightspeed/flame/Button";
import { Badge } from "@lightspeed/flame/Badge";

const LogOutWrapper = styled.div`
  background: #555;
  margin: 24px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 8px;
`;

const UserWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 4px;
  background: rgb(219, 219, 219);
  background: linear-gradient(
    0deg,
    rgba(219, 219, 219, 1) 0%,
    rgba(255, 255, 255, 1) 62%
  );
`;

const SidebarLogout = () => {
  return (
    <LogOutWrapper>
      <UserWrapper>
        <img src={user} alt="user" />
      </UserWrapper>
      <Badge
        style={{ cursor: "pointer" }}
        type="warning"
        onClick={() => window.location.reload()}
      >
        Logout
      </Badge>
    </LogOutWrapper>
  );
};

export default SidebarLogout;
