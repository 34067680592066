const MAX_SAFE_INTEGER = 9007199254740991;

let lastId = 0;
let uniqueGenerationStr = "i";

const genId = (prefix) => {
  if (lastId === MAX_SAFE_INTEGER) {
    lastId = 0;
    uniqueGenerationStr += "i";
  }

  return (prefix ? `${prefix}_` : '') + uniqueGenerationStr + ++lastId;
};

export default genId;
