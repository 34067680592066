import { useState, useEffect } from "react";
import * as sortFns from "../utils/sortFn";

export const useSortState = ({ data, sortByData }) => {
  const [sortState, setSortState] = useState([...Array(20)].map(() => 0));
  const [sortedData, setSortedData] = useState(data);

  useEffect(() => {
    const isSorted = sortState.find((i) => i !== 0);
    if (isSorted) {
      const isSortedIndex = sortState.findIndex((i) => i !== 0);
      const newData = [...data].sort(
        sortFns.customSortGen(sortByData[isSortedIndex].label)
      );
      setSortedData(isSorted === 1 ? newData : newData.reverse());
    } else {
      setSortedData(data);
    }
  }, [sortState, data]);

  const changeSortState = (x) => {
    const newSortState = [...Array(20)].map(() => 0);
    switch (sortState[x]) {
      case 0:
        newSortState[x] = 1;
        break;
      case 1:
        newSortState[x] = 2;
        break;
      default:
        newSortState[x] = 0;
    }
    setSortState([...newSortState]);
  };

  return { sortedData, sortState, changeSortState };
};
