import styled from "@emotion/styled";
import { Link, useLocation } from "react-router-dom";

const Wrapper = styled(Link)`
  font-weight: 600;
  padding: 0 12px;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;

  position: relative;

  &:after {
    content: "";
    top: ${({ top }) => top};
    left: 0;
    width: 100%;
    height: 8px;
    position: absolute;
    background: #5187e0;
    transition: top 0.2s;
  }

  &:hover:after {
    top: 93%;
  }
`;

const CategoryBarItem = ({ label, url, onClick }) => {
  const { pathname } = useLocation();
  const currentItem = url === pathname ? "93%" : "100%";
  return (
    <Wrapper top={currentItem} to={url} onClick={onClick}>
      {label}
    </Wrapper>
  );
};

export default CategoryBarItem;
