import dots from "../../assets/dots.svg";
import styled from "@emotion/styled";

const ButtonStyle = styled.div`
  background: #282828;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100vh;

  img {
    margin: 0;
    height: 24px;
    width: auto;
  }
`;

const ToggleButton = ({ onClick }) => {
  return (
    <ButtonStyle onClick={onClick}>
      <img src={dots} alt="dots" />
    </ButtonStyle>
  );
};

export default ToggleButton;
