import { Alert } from "@lightspeed/flame/Alert";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { uiSelector, setAlert } from "../redux/slices/uiSlice";

const Wrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 50%;
  z-index: 1000;
`;

const Notification = () => {
  const dispatch = useDispatch();
  const {
    alert: { type, title, text },
  } = useSelector(uiSelector);

  const handleClose = () => {
    dispatch(setAlert({ type: "", title: "", text: "" }));
  };

  return (
    <Wrapper>
      <Alert type={type} title={title} onClose={handleClose}>
        {text}
      </Alert>
    </Wrapper>
  );
};

export default Notification;
