import { Input } from "@lightspeed/flame/Input";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { uiSelector } from "../../redux/slices/uiSlice";

const InputStyle = styled.div`
  min-width: 350px;
`;

const InputField = ({ placeholder, value, valueSet }) => {
  const { theme } = useSelector(uiSelector);
  return (
    <InputStyle>
      <Input
        placeholder={placeholder}
        className={theme === "flame" ? "" : "darkmode-input"}
        value={value}
        onChange={(e) => valueSet(e.target.value)}
      />
    </InputStyle>
  );
};

export default InputField;
