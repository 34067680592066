import Wrapper, { ButtonWrapper, Notification } from "../components/Wrapper";
import Button from "../components/ui/form/Button";
import Input from "../components/ui/form/InputField";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, getLogin } from "../redux/slices/authSlice";

const Login = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { error, loading } = useSelector(authSelector);

  const onSubmit = (e) => {
    dispatch(getLogin(e));
  };

  return (
    <Wrapper>
      {/* <button
        onClick={() =>
          dispatch(getLogin({ username: "punk", password: "punk" }))
        }
      >
        login punk
      </button> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="username"
          register={register}
          label="Username"
          tabIndex="1"
        />
        <Input
          name="password"
          type="password"
          label="Password"
          forgotLabel="Forgot password?"
          forgotLink="/recover"
          tabIndex="2"
          register={register}
        />
        <ButtonWrapper>
          <Button register={register} tabIndex="3">
            Sign in
          </Button>
        </ButtonWrapper>
        <Notification>
          {(loading && "Signing in...") ||
            (errors.username && "Username is required") ||
            (errors.password && "Password is required") ||
            error}
          <br />
        </Notification>
      </form>
    </Wrapper>
  );
};

export default Login;
