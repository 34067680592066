import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  popup: { id: "", type: "" },
  alert: { type: "", title: "", text: "" },
  locationQuery: "",
  searchQuery: "",
  theme: "flame",
};

// const themeLight = "flame";
// const themeDark = "experimentaldark";

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setPopup(state, action) {
      state.popup = action.payload;
    },
    setAlert(state, action) {
      state.alert = action.payload;
    },
    setLocationQuery(state, action) {
      state.locationQuery = action.payload;
    },
    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    setTheme(state, action) {
      state.theme = action.payload;
    },
    toggleTheme(state) {
      if (state.theme === "flame") {
        localStorage.setItem("theme", JSON.stringify("experimentaldark"));
        state.theme = "experimentaldark";
      } else {
        state.theme = "flame";
        localStorage.setItem("theme", JSON.stringify("flame"));
      }
    },
  },
});

export const {
  setPopup,
  setAlert,
  setLocationQuery,
  setSearchQuery,
  setTheme,
  toggleTheme,
} = uiSlice.actions;

export const uiSelector = (state) => state.ui;

export default uiSlice.reducer;
