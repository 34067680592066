import styled from "@emotion/styled";
import { Route } from "react-router-dom";

import CategoryBar from "../components/CategoryBar";

// Tables
import ReveneuCentar from "../components/tables/ReveneuCentar";
import Itemizer from "../components/tables/Itemizer";
import Category from "../components/tables/Category";
import Payments from "../components/tables/Payments";
import Tax from "../components/tables/Tax";
import Other from "../components/tables/Other";
import Reports from "../components/tables/Reports";
import Log from "../components/tables/Log";

const Wrapper = styled.div`
  padding: 16px;
  padding-top: 0;

  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const tablesDict = {
  "/details": <ReveneuCentar />,
  "/details/itemizers": <Itemizer />,
  "/details/category": <Category />,
  "/details/payments": <Payments />,
  "/details/tax": <Tax />,
  "/details/other": <Other />,
  "/details/reports": <Reports />,
  "/details/log": <Log />,
};

const tableKeys = Object.keys(tablesDict);

const Details = () => {
  return (
    <Wrapper>
      <CategoryBar />
      {tableKeys.map((i) => (
        <Route exact path={i} key={i}>
          {tablesDict[i]}
        </Route>
      ))}
    </Wrapper>
  );
};

export default Details;
