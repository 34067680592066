import styled from "@emotion/styled";
import spinner from "../assets/spinner.gif";

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Loader = () => {
  return (
    <LoaderWrapper>
      <div>
        <img src={spinner} alt="loader" />
      </div>
    </LoaderWrapper>
  );
};

export default Loader;
