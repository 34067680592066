import styled from "@emotion/styled";
import { Route } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  companiesSelector,
  getCompanies,
  getFloors,
  setCurrentId,
} from "../redux/slices/companiesSlice";

import Home from "./home";
import Details from "./details";

import LocationBar from "../components/LocationBar";
import Sidebar from "../components/Sidebar";
import Loader from "../components/Loader";

const Wrapper = styled.div`
  display: flex;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Layout = () => {
  const dispatch = useDispatch();
  const { loading, companiesList } = useSelector(companiesSelector);

  useEffect(() => {
    const asyncGetCompanies = async () => {
      const companiesPayload = await dispatch(getCompanies());
      dispatch(setCurrentId(companiesPayload.payload[0].company_id));
      dispatch(getFloors());
    };

    asyncGetCompanies();
  }, []);

  if (loading) {
    return (
      <Wrapper>
        <Sidebar />
        <Loader />
      </Wrapper>
    );
  }

  if (!companiesList.length) {
    <Wrapper>
      <Sidebar />
      <p>No Companies</p>
    </Wrapper>;
  }

  return (
    <Wrapper>
      <Sidebar />
      <MainWrapper>
        <LocationBar />
        {<Route path="/details" component={Details} />}
        {<Route exact path="/" component={Home} />}
      </MainWrapper>
    </Wrapper>
  );
};

export default Layout;
