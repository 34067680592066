import Wrapper, { ButtonWrapper, Notification } from "../components/Wrapper";
import Button from "../components/ui/form/Button";
import Input from "../components/ui/form/InputField";

const Recover = () => {
  return (
    <Wrapper>
      <Input register={() => {}} tabIndex="1" label="Username" />
      <ButtonWrapper>
        <Button tabIndex="2">Recover</Button>
      </ButtonWrapper>
      <Notification>
        <br />
      </Notification>
    </Wrapper>
  );
};

export default Recover;
