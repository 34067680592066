import { Input } from "@lightspeed/flame/Input";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const InputStyle = styled.div`
  margin-bottom: 16px;
  min-width: 350px;

  label {
    font-size: 0.7rem;
    margin-bottom: 6px;
    display: block;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  a {
    font-size: 0.7rem;
    text-decoration: none;
    color: rgb(19, 113, 177);
  }
`;

const InputField = ({
  name,
  label,
  forgotLabel,
  forgotLink,
  tabIndex,
  register,
  type = "text",
}) => {
  return (
    <InputStyle>
      <LabelWrapper>
        <label htmlFor={name}>{label}</label>
        {forgotLabel && <Link to={forgotLink}>{forgotLabel}</Link>}
      </LabelWrapper>
      <Input
        name={name}
        id={name}
        type={type}
        tabIndex={tabIndex}
        {...register(name, { required: true })}
      />
    </InputStyle>
  );
};

export default InputField;
